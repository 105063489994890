

















































































































import Vue from 'vue';

export default Vue.extend({
  methods: {
    accept() {
      this.$store.dispatch('setTOSAccepted');
    },
    refuse() {
      this.$emit('tos-refused');
    },
  },
});
