
import Vue from 'vue';
import AzureAuth from '@/plugins/AzureAuth';
import Logo from '@/assets/Logo.vue';
import MSButton from '@/assets/MSButton.vue';
import Communicator from '@/assets/Communicator.vue';
import Learning from '@/assets/Learning.vue';
import VrApp from '@/assets/VrApp.vue';
import ArApp from '@/assets/ArApp.vue';

interface SampleData {
  loggedIn: boolean;
}

export default Vue.extend({
  components: {
    Logo,
    MSButton,
    Communicator,
    Learning,
    VrApp,
    ArApp,
  },
  name: 'AzureComponentVue', // vue component name
  data(): SampleData {
    return {
      loggedIn: AzureAuth.isLoggedIn(),
    };
  },
  props: {
    forceLogin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    async loginHandler() {
      try {
        const resp = await AzureAuth.loginPopup(this.setLogin);
        AzureAuth.log('login handler', resp);
        if (resp) this.setLogin();
      } catch (e) {
        this.loggedIn = false;
      }
    },
    setLogin() {
      AzureAuth.log('set login to true');
      this.loggedIn = true;
      this.$router.push(this.$route.fullPath);
    },
  },
  async mounted() {
    if (this.forceLogin) {
      if (!this.loggedIn) {
        if (!localStorage.getItem('logout')) {
          AzureAuth.log('try login on load');
          await this.loginHandler();
        }
        localStorage.removeItem('logout');
      } else {
        AzureAuth.log('ALREADY LOGGED IN');
      }
    }
  },
});
