import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      light: {
        primary: '#FBC02D',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        black: '#000',
        white: '#fff',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 960,
      lg: 1280,
    },
  },
});
