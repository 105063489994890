import Vue from 'vue';
import Vuex from 'vuex';
import API from '@/plugins/axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tnc_accepted: false,
    tnc_accepted_at: null,
    tnc_resolved: false,
  },
  actions: {
    setTOSAccepted: async ({ commit }) => {
      await API.post('/auth/user/self/tnc_accept/', {}, { baseURL: 'https://api.dev.otis-platform.tech/api/v0/' });
      commit('acceptTOS');
    },
  },
  mutations: {
    acceptTOS: (state, payload = true) => {
      state.tnc_accepted = payload;
      state.tnc_resolved = true;
    },
  },
  modules: {},
});
