import * as msal from '@azure/msal-browser';

class AzureAuth {
  msalConfig: msal.Configuration;
  app: msal.PublicClientApplication;
  scope: string;
  shouldLogin: boolean;
  loginCallbacks: Array<() => void>;
  onLogInCalled: boolean;

  constructor() {
    this.msalConfig = {
      auth: {
        clientId: process.env.VUE_APP_AZURE_AUTH_CLIENT_ID || '',
        authority: process.env.VUE_APP_AZURE_AUTH_AUTHORITY || '',
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };
    this.shouldLogin = false;
    this.loginCallbacks = [];
    this.onLogInCalled = false;
    this.scope = process.env.VUE_APP_AZURE_AUTH_SCOPES || '';
    this.app = new msal.PublicClientApplication(this.msalConfig);
    localStorage.setItem('redirectHandled', 'false');
    this.setupRedirect();
  }

  log(...msgs: unknown[]) {
    if (!process.env.VUE_APP_DEBUG) return;
    console.log(msgs);
  }
  warn(...msgs: unknown[]) {
    if (!process.env.VUE_APP_DEBUG) return;
    console.warn(msgs);
  }

  async setupRedirect() {
    try {
      console.log('setup redirect');
      const tokenResponse = await this.app.handleRedirectPromise();
      this.log('test');
      this.log('tokenResponse', tokenResponse);
      localStorage.setItem('redirectHandled', `true`);
      if (this.shouldLogin) {
        try {
          this.loginPopup();
        } catch {
          this.log('failed');
        }
      }
    } catch (e) {
      this.warn('tokenError: ', e);
      localStorage.setItem('redirectHandled', `false`);
      if (this.shouldLogin) {
        try {
          this.loginPopup();
        } catch {
          this.log('failed');
        }
      }
    }
  }

  onLogin() {
    this.onLogInCalled = true;
    this.loginCallbacks.forEach((callback) => {
      callback();
    });
  }

  redirectHandled() {
    this.log(localStorage.getItem('redirectHandled'));
    return localStorage.getItem('redirectHandled') === 'true';
  }

  isLoggedIn() {
    return !!this.getAccount();
  }

  async loginPopup(callback?: () => void) {
    this.log('loginPopup');
    if (!this.redirectHandled()) {
      this.log('redirectHandled');
      this.shouldLogin = true;
      if (callback) this.loginCallbacks.push(callback);
      return Promise.reject();
    }
    if (this.isLoggedIn()) {
      this.log('already logged in');
      if (!this.onLogInCalled) this.onLogin();
      return Promise.reject();
    }
    try {
      const loginSuccess = await this.app.loginPopup({
        scopes: [this.scope],
      });
      this.onLogin();
      return loginSuccess;
    } catch (err) {
      if (err.errorCode === 'popup_window_error') {
        return this.loginRedirect();
      } else {
        this.warn('loginPopup: ', err);
        return Promise.reject(err);
      }
    }
  }

  async loginRedirect() {
    try {
      return await this.app.loginRedirect({
        scopes: [this.scope],
      });
    } catch (err) {
      this.warn('loginRedirect: ', err);
      return Promise.reject(err);
    }
  }

  getAccount() {
    return this.app.getAllAccounts()[0];
  }

  async logout() {
    localStorage.setItem('logout', 'true');
    this.app.logout();
  }

  async getToken() {
    try {
      this.log('try silent');
      return (
        await this.app.acquireTokenSilent({
          account: this.getAccount(),
          scopes: [this.scope],
        })
      ).accessToken;
    } catch (e) {
      this.log('failed silent: ', e);
      if (e instanceof msal.InteractionRequiredAuthError) {
        return await this.getTokenPopup();
      }
    }
  }

  async getTokenPopup() {
    try {
      this.log('try popup');
      return (
        await this.app.acquireTokenPopup({
          scopes: [this.scope],
        })
      ).accessToken;
    } catch (e) {
      return await this.getTokenRedirect();
    }
  }

  async getTokenRedirect() {
    try {
      this.log('try redirect');
      return await this.app.acquireTokenRedirect({
        scopes: [this.scope],
      });
    } catch (e) {
      return await Promise.reject(new Error(e));
    }
  }
}

export default new AzureAuth();
