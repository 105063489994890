import axios from 'axios';
import AzureAuth from './AzureAuth';

const BASE_URL = process.env.VUE_APP_API_URL;

const API = axios.create({
  baseURL: BASE_URL,
  timeout: 3000,
});

export const setupAxiosToken: (access?: string) => Promise<string> = async (access) => {
  const token = access || (await AzureAuth.getToken());
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return `${token}`;
};

API.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    config.headers.common['Authorization'] = `Bearer ${await AzureAuth.getToken()}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // debugger;
    if (error.request.status === 401) {
      try {
        const token = await setupAxiosToken();

        return axios({
          ...error.config,
          headers: { ...error.config.headers, Authorization: `Bearer ${token}` },
        });
      } catch (e) {
        // await store.dispatch('logout');
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
    // error
  }
);

export default API;
