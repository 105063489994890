


















import Vue from 'vue';

export default Vue.extend({
  name: 'SplashScreen',
  data: () => ({
    // eslint-disable-next-line no-undef
    timer: 0 as unknown as NodeJS.Timeout,
  }),
  mounted() {
    this.timer = setTimeout(() => {
      this.$router.push('/skills');
    }, 1500);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
});
