import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import API, { setupAxiosToken } from '@/plugins/axios';
import SplashScreen from '../views/SplashScreen.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/landing',
    name: 'SplashScreen',
    component: SplashScreen,
    meta: {
      title: 'ILP',
      metaTags: [
        {
          name: 'description',
          content: 'ILP',
        },
        {
          property: 'og:description',
          content: 'ILP',
        },
      ],
    },
  },
  {
    path: '/',
    component: () => import('@/views/Layout.vue'),
    beforeEnter: async (to, from, next) => {
      setupAxiosToken();
      const { data } = await API.get(`/v0/auth/user/self/`, {
        baseURL: process.env.VUE_APP_API_BASE,
      });
      const { tnc_accepted } = data;
      store.commit('acceptTOS', tnc_accepted);
      localStorage.setItem('user', JSON.stringify(data));
      if (to.fullPath === '/') {
        next('/landing');
      } else {
        next();
      }
    },
    children: [
      {
        path: '/skills',
        name: 'Skills',
        component: () => import('@/views/Skills.vue'),
        meta: {
          title: 'ILP - Skills',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Choose skills you want to learn',
            },
            {
              property: 'og:description',
              content: 'ILP - Choose skills you want to learn',
            },
          ],
        },
      },
      {
        path: '/filters',
        name: 'Filters',
        component: () => import('@/views/Filters.vue'),
        beforeEnter: (to, from, next) => {
          next();
        },
        meta: {
          title: 'ILP - Filter',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Courses based on your prefered skills',
            },
            {
              property: 'og:description',
              content: 'ILP - Courses based on your prefered skills',
            },
          ],
        },
      },
      {
        path: '/maltese',
        name: 'Maltese',
        component: () => import('@/views/Maltese.vue'),
        beforeEnter: (to, from, next) => {
          next();
        },
        meta: {
          title: 'ILP - Maltese - Skills Kits',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Maltese - Skills Kits',
            },
            {
              property: 'og:description',
              content: 'ILP - Maltese - Skills Kits',
            },
          ],
        },
      },
      {
        path: '/course/:id',
        name: 'Course',
        component: () => import('@/views/Course.vue'),
        meta: {
          title: 'ILP - Course',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Course details',
            },
            {
              property: 'og:description',
              content: 'ILP - Course details',
            },
          ],
        },
      },
      {
        path: '/units/:id',
        name: 'Unit',
        component: () => import('@/views/Unit.vue'),
        meta: {
          title: 'ILP - Unit',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Unit details',
            },
            {
              property: 'og:description',
              content: 'ILP - Unit details',
            },
          ],
        },
      },
      {
        path: '/skillskit/:id',
        name: 'Skills Kit',
        component: () => import('@/views/SkillsKit.vue'),
        beforeEnter: (to, from, next) => {
          if (from.name === 'Maltese') {
            to.params.maltese = 'true';
            console.log(to);
            next();
          } else {
            next();
          }
        },
        meta: {
          title: 'ILP - Skills Kit',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Skills Kit details',
            },
            {
              property: 'og:description',
              content: 'ILP - Skills Kit details',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/admin',
    component: () => import('@/views/Admin/Layout.vue'),
    beforeEnter: async (to, from, next) => {
      setupAxiosToken();
      const { data } = await API.get(`/v0/auth/user/self/`, {
        baseURL: process.env.VUE_APP_API_BASE,
      });
      if (to.fullPath === '/' || !data.permissions.includes('CAN_USE_ILP_ADMIN_PANEL')) {
        next('/landing');
      } else {
        next();
      }
    },
    children: [
      {
        path: '/',
        name: 'Admin - Units',
        component: () => import('@/views/Admin/Units.vue'),
        meta: {
          title: 'ILP - Admin - Units',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Admin - Units',
            },
            {
              property: 'og:description',
              content: 'ILP - Admin - Units',
            },
          ],
        },
      },
      {
        path: '/admin/unit/new',
        name: 'Admin - Create Unit',
        component: () => import('@/views/Admin/NewUnit.vue'),
        meta: {
          title: 'ILP - Admin - Create Unit',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Admin - Create Unit',
            },
            {
              property: 'og:description',
              content: 'ILP - Admin - Create Unit',
            },
          ],
        },
      },
      {
        path: '/admin/unit/:id/edit',
        name: 'Admin - Edit Unit',
        component: () => import('@/views/Admin/EditUnit.vue'),
        meta: {
          title: 'ILP - Admin - Edit Unit',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Admin - Edit Unit',
            },
            {
              property: 'og:description',
              content: 'ILP - Admin - Edit Unit',
            },
          ],
        },
      },
      {
        path: '/admin/unit/:id',
        name: 'Admin - Unit',
        component: () => import('@/views/Admin/Unit.vue'),
        meta: {
          title: 'ILP - Admin - Unit',
          metaTags: [
            {
              name: 'description',
              content: 'ILP - Admin - Unit',
            },
            {
              property: 'og:description',
              content: 'ILP - Admin - Unit',
            },
          ],
        },
      },
      {
        path: '/admin/*',
        redirect: '/admin/',
      },
    ],
  },
  {
    path: '*',
    redirect: '/landing',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [key: string]: string }) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: HTMLElement) => document.head.appendChild(tag));

  next();
});
export default router;
