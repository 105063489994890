














import Vue from 'vue';
import AzureAuth from './plugins/AzureAuth';
import AzureComponent from '@/components/Auth/AzureComponent.vue';
import TOSDialog from '@/components/TOS/TOSDialog.vue';
import TOSRefused from '@/components/TOS/TOSRefused.vue';

export default Vue.extend({
  name: 'App',
  components: {
    AzureComponent,
    TOSDialog,
    TOSRefused,
  },
  data: () => ({
    tosRefused: false,
  }),
  computed: {
    tosAccepted(): boolean {
      return this.$store.state.tnc_accepted;
    },
    tosResolved(): boolean {
      return this.$store.state.tnc_resolved;
    },
  },
  async mounted() {
    const getToken = async (i: number) => {
      if (i < 10 && !(await AzureAuth.getToken())) {
        setTimeout(() => getToken(i + 1), 200);
      }
    };
    await getToken(0);
  },
});
